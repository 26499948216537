<template>
  <label
    class="toggle body-2-expanded"
    :class="classes"
    @keydown.enter.prevent="handleChange"
    @click.prevent="handleChange"
  >
    <div class="toggle__content">
      <div class="toggle__switch">
        <input
          v-bind="$attrs"
          :id="uid"
          class="toggle__input"
          type="checkbox"
          tabindex="-1"
          :name="$props.name"
          :value="model"
          :disabled="$props.disabled"
          :checked="model"
        />
        <div
          class="toggle__label"
          tabindex="0"
          @keydown="onKeyDown"
        >
          <the-icon
            class="toggle__icon--circle"
            art="solid"
            name="circle"
          />
          <the-icon
            class="toggle__icon--check"
            art="solid"
            name="check"
          />
        </div>
      </div>

      <div
        class="toggle__text"
        :class="['toggle--' + $props.placement]"
      >
        <slot>
          <span
            v-if="$props.label"
            v-text="$props.label"
          />
        </slot>
      </div>
    </div>
  </label>
</template>

<script setup>
import { computed } from 'vue'
import { v4 as uuidv4 } from 'uuid'

// INIT
const model = defineModel({ type: Boolean })

const props = defineProps({
  name: {
    type: String,
    required: true,
  },

  placement: {
    type: String,
    default: 'right',
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  label: {
    type: String,
    default: '',
  },

  size: {
    validator(value) {
      return ['sm', 'md'].includes(value)
    },
    default: 'sm',
  },

  v: {
    type: Object,
    default: () => {},
  },
})

// COMPUTED
const classes = computed(() => {
  return {
    [`toggle--${props.size}`]: true,
    'toggle--error': isInvalid.value,
  }
})

// DATA
const uid = uuidv4()

// COMPUTED
const isInvalid = computed(() => {
  return props.v?.$invalid && props.v?.$error
})

// METHODS
function handleChange() {
  model.value = !model.value
}

function onKeyDown(event) {
  if (event.keyCode === 32) {
    handleChange()
    event.preventDefault()
  }
}
</script>

<style scoped>
.toggle__content {
  display: flex;
  flex-direction: row;
  gap: var(--fixed-spacing-fix-03);
  align-items: center;
  cursor: pointer;
}
.toggle {
  --toggle-animation: cubic-bezier(0, 0, 1, 1);

  display: inline-block;

  &--sm {
    --toggle-off-width: 12px;
    --toggle-off-height: 12px;
    --toggle-off-top: 4px;
    --toggle-off-left: 4px;

    --toggle-hover-left: -8px;
    --toggle-hover-top: -10px;

    --toggle-active-width: 20px;
    --toggle-active-height: 20px;
    --toggle-active-top: 0;
    --toggle-active-left: 0;

    --toggle-checked-width: 18px;
    --toggle-checked-height: 18px;
    --toggle-checked-left: calc(100% - 1px);

    --toggle-checked-icon-left: calc(100% - 15.5px);

    --toggle-checked-hover-top: -10px;
    --toggle-checked-hover-left: 8px;

    --toggle-label-width: 42px;
    --toggle-label-height: 24px;

    --toggle-check-icon-width: 11px;
    --toggle-check-icon-height: 12px;

    --toggle-after-check-icon-top: 1px;
  }

  &--md {
    --toggle-off-width: 16px;
    --toggle-off-height: 16px;
    --toggle-off-top: 6px;
    --toggle-off-left: 6px;

    --toggle-hover-left: -8px;
    --toggle-hover-top: -6px;

    --toggle-active-width: 28px;
    --toggle-active-height: 28px;
    --toggle-active-top: 0;
    --toggle-active-left: 0;

    --toggle-checked-width: 24px;
    --toggle-checked-height: 24px;
    --toggle-checked-left: calc(100% - 2px);

    --toggle-checked-icon-left: calc(100% - 20.5px);

    --toggle-checked-hover-top: -6px;
    --toggle-checked-hover-left: 18px;

    --toggle-label-width: 56px;
    --toggle-label-height: 32px;

    --toggle-check-icon-width: 14px;
    --toggle-check-icon-height: 16px;

    --toggle-after-check-icon-top: 2px;
  }

  &__label {
    --toggle-check-left: 5px;
    --toggle-check-top: 0;
    --toggle-check-bottom: 0;

    --toggle-after-top: 4px;
    --toggle-after-width: 12px;
    --toggle-after-height: 12px;
    --toggle-after-border-radius: 90px;

    border-width: 2px;
    border-style: solid;
  }

  &__text {
    color: var(--on-surface);
    display: flex;
    flex-direction: row;
    column-gap: var(--fixed-spacing-fix-03);
    align-items: center;
  }

  &__input {
    --toggle-disabled-border: 2px solid var(--Interaction-States-disabled-default);
    --toggle-disabled-background: var(--Interaction-States-disabled-default);
  }

  &--left {
    order: -1;
  }

  &--right {
    order: 1;
  }

  &__switch {
    position: relative;
    z-index: var(--dvp-stack-level-element);
  }

  &__input {
    position: absolute;
    height: 0;
    width: 0;
    visibility: hidden;

    &:checked + .toggle__label {
      background-color: var(--Interaction-States-enabled-default);
      border-color: var(--Interaction-States-enabled-default);

      .toggle__icon--check {
        left: var(--toggle-checked-icon-left);
        opacity: 1;
      }

      &::after {
        content: '';
        position: absolute;
        width: var(--toggle-checked-width);
        height: var(--toggle-checked-height);
        left: var(--toggle-checked-left);
        background-color: var(--surface);
        transform: translateX(-100%);
      }
    }

    &:not(:checked) + .toggle__label {
      border-color: var(--outline);

      &::after {
        content: '';
        position: absolute;
        width: var(--toggle-off-width);
        height: var(--toggle-off-height);
        top: var(--toggle-off-top);
        left: var(--toggle-off-left);
        background-color: var(--Interaction-States-enabled-off);
        z-index: -1;
      }
    }
  }

  &__label {
    cursor: pointer;
    display: block;
    border-radius: var(--radius-border-radius-full);
    position: relative;
    text-indent: -9999px;
    width: var(--toggle-label-width);
    height: var(--toggle-label-height);

    &:focus-visible {
      outline: 2px solid var(--on-surface);
      outline-offset: var(--fixed-spacing-fix-01);
      border-radius: var(--radius-border-radius-full);
    }

    .toggle__icon--check {
      position: absolute;
      width: var(--toggle-check-icon-width);
      height: var(--toggle-check-icon-height);
      left: var(--toggle-check-left);
      top: var(--toggle-check-top);
      bottom: var(--toggle-check-bottom);
      margin: auto 0;
      z-index: var(--dvp-stack-level-element);
      opacity: 0;
      color: var(--Interaction-States-enabled-default);
    }

    &::after {
      content: '';
      position: absolute;
      top: var(--toggle-after-check-icon-top);
      width: var(--toggle-after-width);
      height: var(--toggle-after-height);
      border-radius: var(--toggle-after-border-radius);
      transform: translateX(0);
    }

    .toggle__icon--circle {
      position: absolute;
      width: 40px;
      height: 40px;
      color: transparent;
      z-index: -1;
    }
  }

  &:hover {
    .toggle__input:checked + .toggle__label {
      background-color: var(--Interaction-States-hovered-default-a);
      border-color: var(--Interaction-States-hovered-default-a);

      .toggle__icon--check {
        color: var(--Interaction-States-hovered-default-a);
      }
    }

    .toggle__icon--circle {
      color: var(--Interaction-States-hovered-default-b);
    }
  }

  &:active {
    .toggle__input:not(:checked) + .toggle__label::after {
      width: var(--toggle-active-width);
      height: var(--toggle-active-height);
      top: var(--toggle-active-top);
      left: var(--toggle-active-left);
      transition: 0.1s var(--toggle-animation);
    }

    .toggle__icon--circle {
      color: var(--Interaction-States-pressed-default-c);
    }
  }

  &:has(input[disabled]) {
    pointer-events: none;

    .toggle__input {
      &:not(:checked) + .toggle__label {
        border-color: var(--Interaction-States-disabled-default);
        &::after {
          background-color: var(--Interaction-States-disabled-default);
        }
      }

      &:checked + .toggle__label {
        background-color: var(--Interaction-States-disabled-default);
        border-color: var(--Interaction-States-disabled-default);

        .toggle__icon--check {
          color: var(--Interaction-States-disabled-default);
        }

        &::after {
          background-color: var(--surface);
        }
      }
    }
  }

  .toggle__input:checked + .toggle__label .toggle__icon--circle {
    top: var(--toggle-checked-hover-top);
    left: var(--toggle-checked-hover-left);
  }

  .toggle__input:not(:checked) + .toggle__label .toggle__icon--circle {
    top: var(--toggle-hover-top);
    left: var(--toggle-hover-left);
  }
}
</style>
